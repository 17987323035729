import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import config from '@plone/volto/registry';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { useLocation } from 'react-router-dom';

const Matomo = injectLazyLibs(['matomoTracker'])(({ matomoTracker }) => {
  const [cookies] = useCookies();
  const confirmTracking = !!Number(cookies.confirm_tracking);
  const location = useLocation();
  const pathname = location.pathname;
  const loading = useSelector((state) => state.content.get.loading);

  useEffect(() => {
    if (confirmTracking && !loading) {
      setTimeout(() => {
        const tracker = new matomoTracker.default({
          urlBase: config.settings.DSGVOBanner.tracker.urlBase,
          siteId: config.settings.DSGVOBanner.tracker.id,
          configurations: {
            // optional, default value: {}
            disableCookies: false,
            setSecureCookie: true,
          },
        });
        tracker.trackPageView();
      }, 10); // delay to give page title a chance to update
    }
  }, [matomoTracker.default, confirmTracking, pathname, loading]);

  return null;
});

export default Matomo;
