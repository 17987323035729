import type { BlockConfigBase, Content, User } from '@plone/types';

const admin_users = [
  'f.vandijk',
  'in.vandenbroeck',
  'ma.vanrees',
  'e.germonpre',
  'devadmin',
  'zestadmin',
  'admin',
];

export const restrict_managers = ({
  properties,
  block,
  navRoot,
  contentType,
  user,
}: {
  properties: Content;
  block: BlockConfigBase;
  navRoot: Content;
  contentType: string;
  user: User;
}) => {
  if (!user?.username) return true;
  if (admin_users.includes(user?.username)) {
    return false;
  }
  return true;
};
