/* eslint-disable prettier/prettier */
import { H2, Label, Text } from '../../Typography';
import { socials } from './socials';
import { Input } from '../../Inputs';
import { Button } from '../../Button';
import { useBreakpoint } from '../../../helpers/useMedia';
import { defineMessages, useIntl } from 'react-intl';
import { useState } from 'react';

const messages = defineMessages({
  FollowVMM: {
    id: 'FollowVMM',
    defaultMessage: 'Follow VMM on social media', // Volg VMM op sociale media
  },
  StayUpToDate: {
    id: 'StayUpToDate',
    defaultMessage: 'Do you like to stay informed?', // Blijf je graag op de hoogte?
  },
  MonthlyNews: {
    id: 'MonthlyNews',
    defaultMessage:
      'Each month, we curate for you a selection of the most important news items tailored to the environmental professional.',
    // We maken maandelijks voor jou een selectie van de belangrijkste nieuwsberichten op maat van de milieuprofessional.
  },
  SubscribeToNewsletter: {
    id: 'SubscribeToNewsletter',
    defaultMessage: 'Subscribe to the newsletter', // Schrijf je in op de nieuwsbrief
  },
  Email: {
    id: 'Email',
    defaultMessage: 'E-mail', // E-mail
  },
  Subscribe: {
    id: 'Subscribe',
    defaultMessage: 'Subscribe', // Inschrijven
  },
  SubscriptionPending: {
    id: 'Starting subscription...',
    defaultMessage: 'Starting subscription...',
  },
  SubscriptionSuccess: {
    id: 'Successfully subscribed. See your e-mail inbox.',
    defaultMessage: 'Successfully subscribed. See your e-mail inbox.',
  },
  SubscriptionError: {
    id: 'There was an error subscribing you.',
    defaultMessage: 'There was an error subscribing you.',
  },
  AlreadySubscribed: {
    id: 'You are already subscribed.',
    defaultMessage: 'You are already subscribed.',
  },
  SubscribedNewList: {
    id: "Successfully subscribed. Your e-mail address was already in our list, so you don't get an opt-in e-mail.",
    defaultMessage:
      "Successfully subscribed. Your e-mail address was already in our list, so you don't get an opt-in e-mail.",
  },
});

const FlexmailOptIn = () => {
  const intl = useIntl();
  const [status, setStatus] = useState('');

  const handleSubmitFlexmail = () => {
    window.event.preventDefault();
    const email = document.getElementById('news-letter-input').value;
    setStatus('pending');
    const url = '/++api++/@flexmail-opt-in';
    fetch(url, { method: 'POST', body: JSON.stringify({ email }) })
      .then((r) => {
        if (r.status === 204) {
          setStatus('success');
        } else if (r.status === 200) {
          r.json().then((data) => {
            setStatus(data.status || 'success');
          });
        } else {
          setStatus('error');
        }
      })
      .catch(() => {
        setStatus('error');
      });
    return;
  };

  return (
    <>
      <Text
        size="xs"
        weight="500"
        uppercase
        className="socials-block__news-letter-title"
      >
        {intl.formatMessage(messages.SubscribeToNewsletter)}
      </Text>
      {status === 'pending' && (
        <p className="warning">
          {intl.formatMessage(messages.SubscriptionPending)}
        </p>
      )}
      {status === 'already-subscribed' && (
        <p className="warning">
          {intl.formatMessage(messages.AlreadySubscribed)}
        </p>
      )}
      {status === 'subscribed-new-list' && (
        <p className="info">{intl.formatMessage(messages.SubscribedNewList)}</p>
      )}
      {status === 'error' && (
        <p className="error">
          {intl.formatMessage(messages.SubscriptionError)}
        </p>
      )}
      {status === 'success' && (
        <p className="info">
          {intl.formatMessage(messages.SubscriptionSuccess)}
        </p>
      )}
      <form className="socials-block__news-letter-form">
        <Label htmlFor="#news-letter-input">
          {intl.formatMessage(messages.Email)}
          <Input id="news-letter-input" type="email" />
        </Label>
        <Button halfRounded size="large" onClick={() => handleSubmitFlexmail()}>
          {intl.formatMessage(messages.Subscribe)}
        </Button>
      </form>
    </>
  );
};

export const SocialsView = () => {
  const intl = useIntl();
  const isBigScreen = useBreakpoint('lg');

  const socialsElements = (
    <div className="socials-block__links">
      <Text size="xs" weight="500" uppercase>
        {intl.formatMessage(messages.FollowVMM)}
      </Text>
      <div>
        {socials.map(([src, href, size]) => (
          <a href={href} key={src}>
            <img
              src={src}
              style={{
                width: size,
                height: size,
              }}
              alt="Social logo"
            />
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <div className="block socials-block">
      <div className="container">
        <div>
          <H2>{intl.formatMessage(messages.StayUpToDate)}</H2>
          <Text size="lg">{intl.formatMessage(messages.MonthlyNews)}</Text>

          {isBigScreen && socialsElements}
        </div>
        <div>
          <FlexmailOptIn />
          {!isBigScreen && socialsElements}
        </div>
      </div>
    </div>
  );
};
