import { mergeDeepRight } from 'ramda';
import { snelnaarblock } from './components/Blocks/SnelNaarBlock';
import { indicatorHeader } from './components/Blocks/IndicatorHeader';
import { socials } from './components/Blocks/Socials';
import { gridBlock } from './components/Blocks/Grid';
import type { BlocksConfigData, ConfigData } from '@plone/types';
import { gridTeaser, teaser } from './components/Blocks/Teaser';
import { Tooltips } from '@rohberg/volto-slate-glossary/components';
import { listing } from './components/Blocks/Listing';
import { ButtonStylingSchema } from './customizations/components/manage/Blocks/Button/schema';
import ButtonView from './components/Blocks/Button/View';
import ButtonEdit from './components/Blocks/Button/Edit';
import { LeftColumnFacets } from './components/Blocks/Search/LeftColumnFacets';
import { tagSocialBlock } from './components/Blocks/TagSocial';
import ProjectView from './components/Theme/ProjectView';
import { GraphTemplate } from './components/Templates/GraphTemplate';
import PublicationView from './components/Theme/PublicationView';
import ServiceView from './components/Theme/ServiceView';
import CijferView from './components/Theme/Cijfer';
import { questionsBlock } from './components/Blocks/QuestionsBlock';
import { eventSessionBlock } from './components/Blocks/EventSessionBlock';
import { graphBlock } from './components/Blocks/Graph';
import Agenda from './components/Theme/Agenda';
import NieuwsberichtView from './components/Theme/Nieuwsbericht';
import cijferSVG from './assets/icons/bar-chart.svg';
import dienstSVG from './assets/icons/minecart.svg';
import projectSVG from './assets/icons/share.svg';
import warningSVG from './assets/icons/warning.svg';
import { ImageBlockDataAdapter } from './components/Blocks/Image/adapter';
import { imageBlockSchemaEnhancer } from './components/Blocks/Image/schema';
import View from './components/Blocks/Image/View';
import Edit from './components/Blocks/Image/Edit';
import QuoteBlockView from './components/Blocks/QuoteBlock/View';
import QuoteBlockEdit from './components/Blocks/QuoteBlock/Edit';
import { restrict_managers } from './helpers/addBlockRestricted';

import { default as VMMCheckboxFacet } from './components/Blocks/Search/components/VMMCheckboxFacet';
import { default as VMMSelectFacetFilterListEntry } from './components/Blocks/Search/components/VMMSelectFacetFilterListEntry';

import { warningLevelConfig } from './components/Blocks/WarningLevel';

const applyConfig = (config: ConfigData) => {
  // Remove HTML block
  delete (config.blocks.blocksConfig as any).html;
  config.blocks.requiredBlocks = [];
  config.blocks.initialBlocks = {
    Document: [
      { '@type': 'title', fixed: true },
      { '@type': 'description', fixed: true },
    ],
    'News Item': [
      { '@type': 'leadimage', fixed: true, required: true },
      { '@type': 'title', fixed: true, required: true },
      { '@type': 'description', fixed: true, required: false },
      { '@type': 'tagSocialBlock', fixed: true, required: true },
    ],
    Project: [
      { '@type': 'leadimage', fixed: true, required: true },
      { '@type': 'title', fixed: true, required: true },
      { '@type': 'description', fixed: true, required: false },
      { '@type': 'tagSocialBlock', fixed: true, required: true },
    ],
    Event: [
      { '@type': 'leadimage', fixed: true, required: true },
      { '@type': 'title', fixed: true, required: true },
      { '@type': 'description', fixed: true, required: false },
      { '@type': 'tagSocialBlock', fixed: true, required: true },
    ],
    Publication: [
      { '@type': 'title', fixed: true, required: true },
      { '@type': 'description', fixed: true, required: false },
      { '@type': 'tagSocialBlock', fixed: true, required: true },
    ],
    Dienst: [
      { '@type': 'title', fixed: true, required: true },
      { '@type': 'description', fixed: true, required: false },
      { '@type': 'tagSocialBlock', fixed: true, required: true },
    ],
    Cijfer: [
      { '@type': 'title', fixed: true, required: true },
      { '@type': 'description', fixed: true, required: false },
      { '@type': 'tagSocialBlock', fixed: true, required: true },
    ],
    Warning: [
      { '@type': 'warningLevel', fixed: true, required: true },
      { '@type': 'title', fixed: true, required: true },
      { '@type': 'description', fixed: true, required: false },
    ],
  };
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['nl'],
    defaultLanguage: 'nl',
    contentIcons: {
      ...config.settings.contentIcons,
      Cijfer: cijferSVG,
      Dienst: dienstSVG,
      Project: projectSVG,
      Warning: warningSVG,
    },
    appExtras: [
      ...config.settings.appExtras,
      {
        props: {},
        match: '/',
        component: Tooltips,
      },
    ],
    apiExpanders: [
      ...config.settings.apiExpanders,
      {
        match: '',
        GET_CONTENT: ['contextnavigation'],
      },
    ],
    allowed_cors_destinations: [
      'api-dev.vmm.vlaanderen.be',
      'api-tst.vmm.vlaanderen.be',
      'api.vmm.vlaanderen.be',
    ],
  };

  const teaserConfig = teaser(config.blocks.blocksConfig.teaser);
  const gridTeaserConfig = gridTeaser(config.blocks.blocksConfig.teaser);
  const listingConfig = listing(config.blocks.blocksConfig.listing as any);
  config.blocks.blocksConfig = mergeDeepRight<any, any>(
    config.blocks.blocksConfig,
    {
      snelnaarblock,
      indicatorHeader,
      socials,
      tagSocialBlock,
      questionsBlock,
      graphBlock,
      eventSessionBlock,
      teaser: teaserConfig,
      warningLevel: warningLevelConfig,
      listing: listingConfig,
      gridBlock: {
        ...gridBlock,
        allowedBlocks: [snelnaarblock.id, 'text', 'image', 'listing', 'teaser'],
        blocksConfig: {
          snelnaarblock,
          teaser: gridTeaserConfig,
        },
      },
    },
  ) as BlocksConfigData;

  config.blocks.blocksConfig.__button = {
    ...config.blocks.blocksConfig.__button,
    colors: [],
    schemaEnhancer: ButtonStylingSchema,
    view: ButtonView,
    edit: ButtonEdit,
  };

  config.blocks.blocksConfig.quote = {
    ...config.blocks.blocksConfig.quote,
    restricted: false,
  };

  config.blocks.blocksConfig.image = {
    ...config.blocks.blocksConfig.image,
    dataAdapter: ImageBlockDataAdapter,
    schemaEnhancer: imageBlockSchemaEnhancer,
    view: View,
    edit: Edit,
  };

  config.blocks.blocksConfig.quote = {
    ...config.blocks.blocksConfig.quote,
    restricted: false,
    view: QuoteBlockView,
    edit: QuoteBlockEdit,
  };

  config.blocks.blocksConfig.search.variations = [
    {
      id: 'facetsLeftSide',
      title: 'Facets on left side',
      view: LeftColumnFacets,
      isDefault: true,
    },
  ];

  config.blocks.blocksConfig.accordion.schemaEnhancer = ({
    schema,
    formData,
    intl,
  }) => {
    schema.fieldsets[1].fields = schema.fieldsets[1].fields.filter(
      (f) =>
        ['title', 'title_size', 'right_arrows', 'filtering'].indexOf(f) === -1,
    );
    schema.properties.title_size.default = 'h3';
    return schema;
  };

  config.views.contentTypesViews['Publication'] = PublicationView;

  config.views.contentTypesViews['Project'] = ProjectView;

  config.views.contentTypesViews['Cijfer'] = CijferView;

  config.views.contentTypesViews.Graph = GraphTemplate;

  config.views.contentTypesViews['Dienst'] = ServiceView;

  config.views.contentTypesViews['Event'] = Agenda;

  config.views.contentTypesViews['News Item'] = NieuwsberichtView;

  config.settings.DSGVOBanner = {
    ...(config.settings.DSGVOBanner || {}),
    modules: ['tracking', 'thirdparty'],
    tracker: {
      type: 'matomo',
      urlBase: 'https://matomo.vmm.be',
      id: 1,
    },
    showHelpTextForOptions: true,
    cssClasses: {
      bannerAgreeButton: 'branded blue',
      bannerAgreeEssentialButton: 'branded olive',
      bannerAdjustButton: 'branded blue',
    },
  };

  config.blocks.blocksConfig.dsgvoBanner = {
    ...config.blocks.blocksConfig.dsgvoBanner,
    restricted: restrict_managers,
  };

  config.blocks.blocksConfig.search.extensions.facetWidgets.types.push({
    id: 'VMMcheckboxFacet',
    title: 'VMM Checkbox',
    view: VMMCheckboxFacet,
    isDefault: false,
    schemaEnhancer: VMMCheckboxFacet.schemaEnhancer,
    stateToValue: VMMCheckboxFacet.stateToValue,
    valueToQuery: VMMCheckboxFacet.valueToQuery,
    filterListComponent: VMMSelectFacetFilterListEntry,
  });

  config.settings.glossary = {
    ...config.settings.glossary,
    matchOnlyFirstOccurence: true,
    mentionTermInTooltip: true,
    includeAccordionBlock: true,
  };

  config.settings.externalRoutes = [
    { match: '/extra/' },
    { match: '/indicatorapi' },
    { match: '/kernapi' },
    { match: '/bestanden' },
    { match: '/waterloket' },
  ];

  return config;
};

export default applyConfig;
