import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import View from './View';
import type { BlockEditProps } from '@plone/types';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  eventSessionBlock: {
    id: 'Event agenda block',
    defaultMessage: 'Event agenda block',
  },
  agendaTitle: {
    id: 'agendaTitle',
    defaultMessage: 'Agenda title',
  },
  agendaTitlePlaceholder: {
    id: 'agendaTitlePlaceholder',
    defaultMessage: 'Enter agenda title',
  },
  sessionHours: {
    id: 'sessionHours',
    defaultMessage: 'Session timing',
  },
  sessionHoursPlaceholder: {
    id: 'sessionHoursPlaceholder',
    defaultMessage: 'Session start and end time',
  },
  sessionTitle: {
    id: 'sessionTitle',
    defaultMessage: 'Session title',
  },
  sessionTitlePlaceholder: {
    id: 'sessionTitlePlaceholder',
    defaultMessage: 'Enter a session title',
  },
  sessionSpeaker: {
    id: 'sessionSpeaker',
    defaultMessage: 'Speaker(s)',
  },
  sessionSpeakerPlaceholder: {
    id: 'sessionSpeakerPlaceholder',
    defaultMessage: 'Enter one or more speakers',
  },
  sessionDescription: {
    id: 'sessionDescription',
    defaultMessage: 'Session description',
  },
  sessionDescriptionPlaceholder: {
    id: 'sessionDescriptionPlaceholder',
    defaultMessage: 'Enter a session description',
  },
});

const SessionSchema = (props) => {
  return {
    title: useIntl().formatMessage(messages.eventSessionBlock),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['agenda', 'sessionhours', 'title', 'speaker', 'text'],
      },
    ],
    properties: {
      agenda: {
        id: 'agendatitle',
        title: useIntl().formatMessage(messages.agendaTitle),
        placeholder: useIntl().formatMessage(messages.agendaTitlePlaceholder),
      },

      sessionhours: {
        id: 'sessionhours',
        title: useIntl().formatMessage(messages.sessionHours),
        placeholder: useIntl().formatMessage(messages.sessionHoursPlaceholder),
      },

      title: {
        id: 'sessiontitle',
        title: useIntl().formatMessage(messages.sessionTitle),
        placeholder: useIntl().formatMessage(messages.sessionTitlePlaceholder),
      },

      speaker: {
        id: 'speaker',
        title: useIntl().formatMessage(messages.sessionSpeaker),
        placeholder: useIntl().formatMessage(
          messages.sessionSpeakerPlaceholder,
        ),
      },

      text: {
        id: 'text',
        title: useIntl().formatMessage(messages.sessionDescription),
        placeholder: useIntl().formatMessage(
          messages.sessionDescriptionPlaceholder,
        ),
      },
    },
    required: ['sessionhours', 'title'],
  };
};

const Edit = (props: BlockEditProps) => {
  const { onChangeBlock, block, data, selected, blocksErrors, schemaEnhancer } =
    props;

  const intl = useIntl();
  const schema = schemaEnhancer
    ? schemaEnhancer(SessionSchema({ ...props, intl }), props)
    : SessionSchema({ ...props, intl });

  return (
    <>
      <View {...props} />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(
            id: keyof typeof data,
            value: (typeof data)[keyof typeof data],
          ) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
          errors={blocksErrors}
        />
      </SidebarPortal>
    </>
  );
};
export default Edit;
