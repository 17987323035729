import EventSessionBlock from '../../EventSessionBlock';
import type { BlockViewProps } from '@plone/types';

const View = ({
  data,
}: BlockViewProps & {
  data: {
    agenda: string;
    sessionhours: string;
    title: string;
    text: string;
    speaker: string;
  };
  isEditMode: boolean;
}) => {
  return (
    <EventSessionBlock
      agenda={data.agenda}
      sessionhours={data.sessionhours}
      title={data.title}
      text={data.text}
      speaker={data.speaker}
    />
  );
};

export default View;
