import icon from '@plone/volto/icons/content-listing.svg';
import Edit from './Edit';
import View from './View';
import { restrict_managers } from '../../../helpers/addBlockRestricted';

export const eventSessionBlock = {
  id: 'eventSessionBlock',
  title: 'Event agenda',
  edit: Edit,
  view: View,
  icon: icon,
  group: 'common',
  restricted: restrict_managers,
  mostUsed: false,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
