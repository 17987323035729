import DRAUGHT from '../../../assets/indicators/draught.svg';
import FLOOD from '../../../assets/indicators/flood.svg';
import AIR_QUALITY from '../../../assets/indicators/air_quality.svg';
import RAINFALL from '../../../assets/indicators/rainfall.svg';
import TIDE from '../../../assets/indicators/tide.svg';
//import SWIMMING from '../../../assets/indicators/swimming.svg';

export type IndicatorStatus =
  | 'Zeer goed'
  | 'Goed'
  | 'Geen risico'
  | 'Normaal'
  | 'Verhoogd risico'
  | 'Slecht';
export type Indicator = {
  id: string;
  name: string;
  icon: string | null;
  status: IndicatorStatus | null;
  futureStatus: IndicatorStatus | null;
  color: string;
  href: string;
};

const map: {
  [name: string]: {
    name: string;
    icon: string | null;
    href: string;
  };
} = {
  lucht: {
    name: 'Luchtkwaliteit',
    icon: AIR_QUALITY,
    href: '/feiten-cijfers/lucht/actuele-luchtkwaliteit',
  },
  overstroming: {
    name: 'Overstromingen',
    icon: FLOOD,
    href: 'https://waterinfo.vlaanderen.be/Themas#item=overstroming/actueel',
  },
  neerslag: {
    name: 'Neerslag',
    icon: RAINFALL,
    href: 'https://waterinfo.vlaanderen.be/Themas#item=neerslag/actueel',
  },
  droogte: {
    name: 'Droogte',
    icon: DRAUGHT,
    href: 'https://waterinfo.vlaanderen.be/Themas#item=droogte/actueel',
  },
  getij: {
    name: 'Getij',
    icon: TIDE,
    href: 'https://waterinfo.vlaanderen.be/Themas#item=getij/actueel',
  },
  //  zwemwater: {
  //    name: 'Zwemwater',
  //    icon: SWIMMING,
  //    href: 'https://kwaliteitzwemwater.be',
  //  },
};
export const indicators = Object.keys(map);

export const getIndicators = async () => {
  const list = [];
  indicators.forEach(function (key) {
    let item = structuredClone(map[key]);
    item['id'] = key;
    item['status'] = null;
    item['futureStatus'] = null;
    item['color'] = '';
    list.push(item);
  });
  return fetch('/++api++/@indicatorapi').then((r) => {
    if (!r.ok) {
      return list as Indicator[];
    }
    return (r.json() as Promise<typeof apiResponse>).then((r) => {
      if (!r.value || !r.value.forEach) {
        return list as Indicator[];
      }
      r.value.forEach(function (v) {
        if (v.naam.indexOf('actueel') === -1) return;
        const name = v.naam.replace('-actueel', '');
        let indicator = list.find((item) => item.id === name);
        if (!indicator) return;
        indicator['status'] = v.status_beschrijving as IndicatorStatus;
        indicator['color'] = v.kleur;
        // Some have a future status in a different value.
        let future = r.value.find((item) => item.naam === `${name}-morgen`);
        if (future)
          indicator['futureStatus'] =
            future.status_beschrijving as IndicatorStatus;
      });
      return list as Indicator[];
    });
  });
};

// export const indicators: Indicator[] = [
//   {
//     name: 'Lucht',
//     status: 'Goed',
//     color: '#00E789',
//     futureStatus: 'Zeer goed',
//     icon: AIR_QUALITY,
//     iconDark: AIR_QUALITY_DARK,
//     href: '#',
//   },
//   {
//     name: 'Overstroming',
//     status: 'Geen risico',
//     color: '#00E789',
//     futureStatus: 'Verhoogd risico',
//     icon: FLOOD,
//     iconDark: FLOOD_DARK,
//     href: '#',
//   },
//   {
//     name: 'Droogte',
//     status: 'Normaal',
//     color: '#20E5F1',
//     futureStatus: 'Normaal',
//     icon: DRAUGHT,
//     iconDark: DRAUGHT_DARK,
//     href: '#',
//   },
// ];
