import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { UniversalLink } from '@plone/volto/components';
import { H5 } from '../../Typography';
import PartnerLogo from './PartnerLogo';
import { defineMessages, useIntl } from 'react-intl';

type ProjectViewProps = {
  content: {
    title: string;
    description: string;
    projectstatus: { title: string };
    looptijd: string;
    budget: string;
    financiering: string;
    projectverantwoordelijke: string;
    locatie: string;
    url: string;
    logos: {
      token: string;
    }[];
  };
};

// Define translatable messages
const messages = defineMessages({
  projectDetails: {
    id: 'ProjectDetails',
    defaultMessage: 'Project details', // Project details
  },
  status: {
    id: 'Status',
    defaultMessage: 'Status', // Status
  },
  looptijd: {
    id: 'Looptijd',
    defaultMessage: 'Duration', // Looptijd
  },
  budget: {
    id: 'Budget',
    defaultMessage: 'Budget', // Budget
  },
  financiering: {
    id: 'Financiering',
    defaultMessage: 'Funding', // Financiering
  },
  projectLead: {
    id: 'ProjectLead',
    defaultMessage: 'Project lead', // Projectverantwoordelijke
  },
  location: {
    id: 'Location',
    defaultMessage: 'Location', // Locatie
  },
  url: {
    id: 'URL',
    defaultMessage: 'URL', // URL
  },
});

const ProjectView = ({ content }: ProjectViewProps) => {
  const intl = useIntl();
  var urlhost = '';
  try {
    var projecturl = new URL(content?.url);
    urlhost = projecturl?.hostname;
  } catch (e) {}

  return (
    <div id="page-document" className="project">
      <div className="project-flex">
        <div className="project-left">
          <RenderBlocks content={content} />
        </div>
        <div className="project-right">
          {content?.logos.length > 0 ? (
            <div className="right-side-section">
              <div className="partners">
                {content?.logos?.map((logo) => (
                  <PartnerLogo partner={logo.token} key={logo.token} />
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="right-side-section">
            <H5>{intl.formatMessage(messages.projectDetails)}</H5>
            <div className="details">
              {content?.projectstatus?.title && (
                <div className="detail-item">
                  <p>{intl.formatMessage(messages.status)}</p>
                  <p className="project-status">
                    {content?.projectstatus?.title}
                  </p>
                </div>
              )}
              {content?.looptijd && (
                <div className="detail-item">
                  <p>{intl.formatMessage(messages.looptijd)}</p>
                  <p>{content?.looptijd}</p>
                </div>
              )}
              {content?.budget && (
                <div className="detail-item">
                  <p>{intl.formatMessage(messages.budget)}</p>
                  <p>{content?.budget}</p>
                </div>
              )}
              {content?.financiering && (
                <div className="detail-item">
                  <p>{intl.formatMessage(messages.financiering)}</p>
                  <p>{content?.financiering}</p>
                </div>
              )}
              {content?.projectverantwoordelijke && (
                <div className="detail-item">
                  <p>{intl.formatMessage(messages.projectLead)}</p>
                  <p>{content?.projectverantwoordelijke}</p>
                </div>
              )}
              {content?.locatie && (
                <div className="detail-item">
                  <p>{intl.formatMessage(messages.location)}</p>
                  <p>{content?.locatie}</p>
                </div>
              )}
              {content?.url && (
                <div className="detail-item">
                  <p>{intl.formatMessage(messages.url)}</p>
                  {urlhost.length > 0 ? (
                    <UniversalLink openLinkInNewTab={true} href={content?.url}>
                      {urlhost}
                    </UniversalLink>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectView;
