import { H2 } from '../Typography';
import './index.scss';

type EventSessionBlockProps = {
  agenda: string;
  sessionhours: string;
  title: string;
  text: string;
  speaker: string;
};

const EventSessionBlock = ({
  agenda,
  sessionhours,
  title,
  text,
  speaker,
}: EventSessionBlockProps) => {
  return (
    <div className="event-session">
      {agenda.trim() && <H2>{agenda}</H2>}
      <div className="session-info">
        <div className="session-left">
          {sessionhours.trim() && (
            <span className="session-timing">{sessionhours}</span>
          )}
          {title.trim() && <span className="session-item">{title}</span>}
        </div>
        <div className="session-right">
          {speaker.trim() && <span className="session-speaker">{speaker}</span>}
          {text.trim() && <span className="session-text">{text}</span>}
        </div>
      </div>
    </div>
  );
};

EventSessionBlock.defaultProps = {
  agenda: '',
  sessionhours: '',
  title: '',
  text: '',
  speaker: '',
};

export default EventSessionBlock;
