import type { BlockViewProps, RelatedItem } from '@plone/types';
import ImageGallery from 'react-image-gallery';
import { flattenToAppURL } from '@plone/volto/helpers';
import imageError from '../../../assets/image-not-found.svg';

type ListingItem = RelatedItem;
export const AlbumTemplate = ({
  items,
  styles,
}: BlockViewProps & {
  items: ListingItem[];
  blocks_layout: { items: any[] };
  styles: {
    gridCutoff: 2 | 3 | 4;
    galleryIndex: boolean;
    photoThumbs: boolean;
    photoDescription: boolean;
    infiniteLoop: boolean;
  };
}) => {
  function getPhotos() {
    const imagelist = [];
    items.map((item) => {
      let original = '';
      let thumbnail = ' ';
      if (item.image_field) {
        let field = item.image_scales[item.image_field][0];
        original = flattenToAppURL(item['@id'] + '/' + field.download);
        if (styles?.photoThumbs && field.scales?.mini) {
          thumbnail = flattenToAppURL(
            item['@id'] + '/' + field.scales.mini.download,
          );
        }
      }
      imagelist.push({
        original: original,
        thumbnail: thumbnail,
        originalAlt: item?.title,
        originalTitle: item?.title,
        description: styles?.photoDescription ? item.description : null,
        loading: 'lazy',
      });
      return item;
    });
    return imagelist;
  }

  return (
    <div className="vmm-listing__items">
      <ImageGallery
        items={getPhotos()}
        lazyLoad={true}
        infinite={styles?.infiniteLoop ? true : false}
        showIndex={styles?.galleryIndex ? true : false}
        onErrorImageURL={imageError}
        showThumbnails={styles?.photoThumbs ? true : false}
        showBullets={styles?.photoThumbs ? false : true}
      />
    </div>
  );
};
