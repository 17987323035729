import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { TextWithGlossaryTooltips } from '@rohberg/volto-slate-glossary/utils';

const messages = defineMessages({
  edition: {
    id: 'Edition',
    defaultMessage: 'Edition',
  },
});

const DescriptionBlockView = ({ properties, metadata }) => {
  const intl = useIntl();
  const publicationDate = (metadata || properties)['published'] || '';
  let pubDate = '';
  if ((metadata || properties)['published'] || '') {
    pubDate =
      intl.formatMessage(messages.edition) +
      ' ' +
      Intl.DateTimeFormat('nl-NL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(publicationDate)) +
      ' – ';
  }
  let description = (metadata || properties)['description'] || '';
  description = TextWithGlossaryTooltips({ text: description });

  return (
    <p className="documentDescription">
      {pubDate}
      {description}
    </p>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DescriptionBlockView.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  metadata: PropTypes.objectOf(PropTypes.any),
};

export default DescriptionBlockView;
