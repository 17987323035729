import { useRef, useEffect } from 'react';
import { Checkbox, Header } from 'semantic-ui-react';
import {
  selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';

/**
 * A facet that uses radio/checkboxes to provide an explicit list of values for
 * filtering
 */
const VMMCheckboxFacet = (props) => {
  // using refs to avoid effect being re-executed
  const initialPropsRef = useRef(props);
  const onChangeRef = useRef(props.onChange);
  onChangeRef.current = props.onChange;

  useEffect(() => {
    const initialProps = initialPropsRef.current;
    const { choices, value, facet } = initialProps;
    const onChange = onChangeRef.current;
    if (choices.length === value.length) {
      onChange(facet.field.value, []);
    }
  }, [initialPropsRef, onChangeRef]);

  const { facet, choices, isMulti, onChange, value, isEditMode } = props;
  const facetValue = value;

  return (
    <div className="checkbox-facet">
      <Header as="h4">{facet.title ?? facet?.field?.label}</Header>
      <div className="entries">
        {choices.map(({ label, value }) => (
          <div
            className={label.includes(' > ') ? 'entry sub' : 'entry'}
            key={value}
          >
            <Checkbox
              id={value.replace(' > ', '-')}
              disabled={isEditMode}
              label={label.split(' > ').splice(-1, 1)[0]}
              radio={!isMulti}
              checked={
                isMulti
                  ? !!facetValue?.find((f) => f.value === value)
                  : facetValue && facetValue.value === value
              }
              onChange={(e, { checked }) =>
                onChange(
                  facet.field.value,
                  isMulti
                    ? [
                        ...facetValue
                          .filter((f) => f.value !== value)
                          .map((f) => f.value),
                        ...(checked ? [value] : []),
                      ]
                    : checked
                      ? value
                      : null,
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

VMMCheckboxFacet.schemaEnhancer = selectFacetSchemaEnhancer;
VMMCheckboxFacet.stateToValue = selectFacetStateToValue;
VMMCheckboxFacet.valueToQuery = selectFacetValueToQuery;

export default VMMCheckboxFacet;
